@font-face {
  font-family: "ColfaxAI";
  src: url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff2)
      format("woff2"),
    url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ColfaxAI";
  src: url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff2) format("woff2"),
    url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}
.main,
.main input {
  font-size: 16px;
  line-height: 24px;
  color: #353740;
  font-family: "ColfaxAI", Helvetica, sans-serif;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}
.main .icon {
  width: 34px;
}
.main h3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: #202123;
  margin: 16px 0 40px;
}
.main form {
  display: flex;
  flex-direction: column;
  width: 320px;
}
.main input[type="text"] {
  padding: 12px 16px;
  border: 1px solid #10a37f;
  border-radius: 4px;
  margin-bottom: 24px;
  outline-color: #10a37f;
}
.main input[type="name"] {
  text-align: center;
  padding: 12px 16px;
  border: 1px solid #10a37f;
  border-radius: 4px;
  margin-bottom: 24px;
  outline-color: #10a37f;
}
.main ::placeholder {
  color: #8e8ea0;
  opacity: 1;
}
.main input[type="submit"] {
  padding: 12px 0;
  color: #fff;
  background-color: #10a37f;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  overflow-x: scroll;
  margin-top: 12px; /* Add 12px space above the submit button */

}
.main .result {
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: white; /* Set the text color to white */
}
.main .play {
  text-align: center;
  margin-top: 20px;
}

.main .button {
  padding: 10px 20px;
  font-family: "ColfaxAI", Helvetica, sans-serif;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.main .button:hover {
  background-color: #45a049;
}
#error-message {
  color: red;
}
.sent {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.sent p {
  background-color: #2ecc71;
  color: #fff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
}

.received {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.received p {
  background-color: #f1f0f0;
  color: #333;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}
.blueButton {
  cursor: pointer;
  background-color: blue;
  color: white;
}

.blueButton:hover {
  background-color: darkblue;
}